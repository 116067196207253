import firstBenefit from "@images/common/benefit1.svg"
import thirdBenefit from "@images/common/benefit3.svg"
import hat from "@images/common/hat.svg"
import tax from "@images/common/tax.svg"
import golocker from "@images/common/goLocker-logo.png"
import lulu from "@images/common/lulu.png"

export const HEADER_BUTTON_NAVIGATION_URL = "/contact-us"
export const HEADER_DESCRIPTION =
  "Whether you are a medtech startup, clinic, doctor, wellness business, or any other healthcare company, our expert developers can create customized apps and digital solutions to meet your specific needs."
export const HEADER_TITLE = "Software development for healthcare companies"
export const HEADER_BUTTON_TEXT = "Let's chat"
export const MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "Healthcare app development services"
export const MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION =
  "We offer the following healthcare app development services:"
export const CALL_TO_SECTION_TITLE = "Let's discuss your digital product"
export const CALL_TO_SECTION_BUTTON_TEXT = "Contact us"
export const CALL_TO_SECTION_BUTTON_URL = "/contact-us"
export const MAIN_OFFERS_PRESENTATION_WITH_IMAGE_TITLE = "Our custom healthcare software development solutions include:"

export const FAQ_SECTION_ITEMS = [
  {
    title:
      "What custom healthcare solutions can Impressit deliver to us?",
    text: "We bring our extensive experience and full-stack expertise to the table to develop specialized healthcare software tailored to your unique needs. Our capabilities include: - Practice management software - Electronic Health Records / Electronic Medical Records - Telemedicine software - Remote Patient Monitoring - Healthcare CRM - Appointment booking software - Health tracking apps - Medical billing software - Mobile health (mHealth) apps",
  },
  {
    title: "Are you developing mobile healthcare applications?",
    text: "We leverage the latest technological advancements to offer healthcare mobile applications for both medical professionals and patients. Our apps transform devices into powerful medical tools that can analyze, monitor, and securely transmit patient data to medical systems or doctors for further analysis. Get the best healthcare mobile app development solutions from our expert team today.",
  },
  {
    title:
      "I want to develop a custom medical CRM. Where do I begin?",
    text: "1. Define your goals and requirements. Before starting, you should define your goals for the CRM system and the specific features and functionality that you require. This will help you to identify the scope of the project and ensure that everyone involved is aligned on what needs to be achieved. 2. Choose a development team. Developing a custom medical CRM requires specialized skills and expertise. You'll need to find a development team with experience in healthcare CRM development, as well as the technical skills needed to build the system. Look for a team that has experience building CRM systems in the healthcare industry and a track record of delivering successful projects. 3. Design the system architecture. The architecture of your medical CRM will have a significant impact on its functionality and scalability. Work with your development team to design a system architecture that meets your needs and can accommodate future growth and changes. 4. Develop the system. Once you have defined your requirements and designed the system architecture, your development team can begin building the system. Work closely with your team to ensure that the system is being developed according to your requirements and that it meets all necessary compliance and security standards. 5. Test and deploy the system. Before launching the system, it's important to thoroughly test it to ensure that it's working properly and meets all of your requirements. Once testing is complete, you can deploy the system and start using it to manage your healthcare organization's relationships with patients, providers, and other stakeholders.",
  },
  {
    title: "Do you provide custom healthcare software development?",
    text: "At Impressit, we excel in creating personalized healthtech software, including job-specific medical solutions and custom-designed mobile applications. While it may require more investment than pre-made products, our custom healthcare software ensures a tailor-made solution that perfectly aligns with your unique needs and requirements.",
  },
  {
    title: "What software systems do hospitals use?",
    text: "1. Electronic Health Record (EHR) systems - used to store and manage patient health information, including medical history, medications, lab results, and more. 2. Patient Billing and Management systems - used to manage patient billing, insurance claims, and other financial aspects of healthcare. 3. Picture Archiving and Communication System (PACS) - used to store and manage medical images, such as X-rays and MRIs. 4. Laboratory Information Management Systems (LIMS) - used to manage laboratory operations, including sample tracking, result management, and quality control. 5. Pharmacy Management Systems - used to manage medication dispensing, inventory, and order tracking. 6. Radiology Information Systems (RIS) - used to manage radiology workflows and imaging procedures. 7. Hospital Information Systems (HIS) - used to manage all aspects of hospital operations, including admissions, discharges, and transfers, as well as clinical and administrative functions. These are just a few examples of the software systems that hospitals use. The specific systems used can vary depending on the hospital's size, specialty, and individual needs.",
  },
  {
    title:
      "What are 5 different types of software used in the healthcare industry?",
    text: "1. Electronic Health Record (EHR) Software: This software is used to store and manage patient health information electronically, including medical history, lab results, and treatment plans. 2. Medical Billing Software: This software is used to automate the billing and coding process, streamlining revenue cycle management for healthcare providers. 3. Practice Management Software: This software is used to manage the day-to-day operations of medical practice, including scheduling appointments, managing patient records, and processing insurance claims. 4. Telehealth Software: This software enables remote medical consultations, allowing patients to receive care from the comfort of their own homes. 5, Medical Imaging Software: This software is used to capture, store, and analyze medical images such as X-rays, MRIs, and CT scans.",
  },
  {
    title: "What are the unique features of medical software?",
    text: "Medical software often includes features such as electronic health records (EHRs), appointment scheduling, billing and coding, prescription management, and patient portals. It must also comply with healthcare regulations and privacy laws, be secure, and have interoperability with other systems. Additionally, medical software may have specialized functions for areas such as telemedicine, radiology, and medical imaging.",
  },
  {
    title: "What are the popular healthcare development trends?",
    text: "Several popular healthcare development trends have gained momentum in recent years. One is the use of telemedicine and telehealth technologies, which allow patients to receive medical care remotely through virtual consultations, remote monitoring, and digital health tools. Another trend is the use of big data analytics and artificial intelligence in healthcare to improve patient outcomes, personalize treatments, and reduce costs. Additionally, mobile health apps and wearables are increasingly being used to improve patient engagement, track health data, and provide real-time insights. Blockchain technology is also being explored for improving data privacy, interoperability, and supply chain management. Finally, there is a growing focus on cybersecurity and data protection in healthcare due to the sensitive nature of medical data.",
  },
]

export const FEEDBACK_SECTION_ITEMS = [
  {
    member: "Ross Ducat",
    position: "Founder & CEO, Lulu Light",
    logoSrc: lulu,
    feedbackText: "Their speed and knowledge were impressive",
  },
  {
    member: "Sherin Davis",
    position: "Chief Product Officer, GoLocker",
    logoSrc: golocker,
    feedbackText: "Overall, they are great partners",
  },
];

export const MAIN_OFFERS_PRESENTATION_WITH_IMAGE_ITEMS = [
  {
    title: "EHR/EMR software development",
    imgSrc: "https://files.impressit.io/impressit_team_min_5ca76fa91d.jpg",
    text: "Transform your healthcare practice with Impressit's expert EHR/EMR software development team. Whether you need to upgrade your current EHR system, integrate it with other healthcare networks, add new features, or build a brand-new one from the ground up, we have you covered. Our specialists will ensure your system is fully interoperable, HIPAA compliant, agile, and financially viable using the best EHR/EMR software development practices available. Let us help you streamline your workflow, improve patient care, and stay ahead in today's ever-changing healthcare industry.",
  },
  {
    title: "Telemedicine software development",
    imgSrc: "https://files.impressit.io/impressit_developer2_44d29e16d4.jpg",
    text: "Do you want to expand your medical practice beyond the traditional brick-and-mortar model? Impressit can help you with our telemedicine app development service! We create web and mobile interoperable telemedicine solutions that allow remote health monitoring and meet the strict HIPAA standards for security and privacy. Our team of experienced medical software engineers will work with you to engineer custom telehealth solutions that cater to your unique needs. Whether you want to integrate telemedicine capabilities into your existing EHR system or build a new one from scratch, Impressit covered your offer. Contact us today to learn more about our telemedicine software development services!",
  },
  {
    title: "mHealth app development",
    imgSrc: "https://files.impressit.io/impressit_team2_min_b54ef68ed0.jpg",
    text: "At Impressit, we provide comprehensive custom mhealth app development services that boost digital advancement in medical care. Our skilled healthcare app developers have a proven record of developing highly compliant mHealth apps that enhance quality care and streamline organizational workflows for healthcare providers of all scales.",
  },
  {
    title: "Healthcare CRM solutions",
    imgSrc: "https://files.impressit.io/impressit_coder_2242bd42fa.jpg",
    text: "Looking for industry-specific healthcare CRM solutions to streamline your patient management process? Impressit offers customized healthcare CRM software development services that cater to your unique needs while ensuring HIPAA compliance and data security. Our solutions help attract new patients, enhance patient loyalty, optimize resource distribution, and automate sales and marketing processes. Choose Impressit for efficient patient management and high-quality medical care.",
  },
  {
    title: "AI in Healthcare",
    imgSrc: "https://files.impressit.io/kostiantyn_586068ccbc.png",
    text: "Our advanced AI solutions are redefining healthcare, delivering precise diagnostics, proactive health management, and operational efficiency. Harness data-driven insights to make informed decisions, streamline operations, and enhance the patient experience. From wearables data analysis to personalized care, we are at the forefront of healthcare innovation.",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Custom healthcare software development",
    text: "Looking for a reliable healthcare software development partner? We specialize in developing medical software that meets the highest industry standards, from electronic health records to medical imaging and diagnostic tools. With our in-depth understanding of the healthcare industry and cutting-edge technology expertise, we are dedicated to delivering innovative solutions that meet your unique business needs. Trust us to bring your healthcare software ideas to life with our comprehensive development services. Contact us today to learn more about how we can help you achieve your goals through top-quality medical software engineering and developing medical applications.",
  },
  {
    title: "Healthcare technology consulting",
    text: "Our healthcare digital transformation consulting is designed to help healthcare businesses achieve their goals. We provide healthcare technology consulting for startups and SME. From strategy development to implementation and optimization, our team of experts has the experience and expertise to guide you through every step of the process. It does not matter what you are attempting to accomplish, such as patient outcomes, streamlining operations, or driving growth, we can assist you in reaching your objectives with the aid of inventive technology services.",
  },
  {
    title: "Medical software developer on demand",
    text: "Looking for expert medical software engineers to help bring your healthcare software ideas to life? Our medical software engineers are experienced in developing top-of-the-line software solutions for the healthcare industry. We specialize in a range of services, including software development, quality assurance, project management, and more. At Impressit, we understand the importance of having a reliable and efficient healthcare software system in place. That's why our medical software developers utilize the latest technologies and programming languages to develop high-quality software solutions that are scalable, secure, and user-friendly.",
  },
]

export const CASE_STUDIES = [
  {
    id: "Case-studies_4",
    logo: { url: "https://files.impressit.io/hcsg_logo_b13c595a0d.svg" },
    metaUrl: "hcsg",
    title: "Mobile development team augmentation for a health-tech product",
    wideImage: { url: "https://files.impressit.io/3hcsg_2919bffbce.svg" },
  },
  {
    id: "Case-studies_11",
    logo: {
      url: "https://files.impressit.io/carbon_Health_Logo_b83b27ac02.svg",
    },
    metaUrl: "carbon",
    title: "DevOps services for unique healthcare company",
    wideImage: {
      url: "https://files.impressit.io/carbon_Health_Main_76fb4c35e0.svg",
    },
  },
]

export const WHY_CHOOSE_ITEMS = [
  {
    title: "Commitment",
    iconFromMarkupPath: firstBenefit,
    description:
      "At Impressit, we're dedicated to supporting you throughout every phase of the XR development lifecycle. Your success is our priority, and we're as determined as you are to see your business thrive.",
  },
  {
    title: "Time & costs savings",
    iconFromMarkupPath: tax,
    description:
      "Investing in premium XR development services now means avoiding future product issues. With Impressit, you'll benefit from solid, high-quality XR products that save you time and money in the long run. ",
  },
  {
    title: "Reduced Risks",
    iconFromMarkupPath: thirdBenefit,
    description:
      "While opting for freelance XR developers might seem like a cost-effective choice, it comes with inherent risks such as security vulnerabilities and poorly written code. By partnering with Impressit, you mitigate these risks, benefiting from our established team's expertise, streamlined documentation, and code quality assurance.",
  },
  {
    title: "Experience",
    iconFromMarkupPath: hat,
    description:
      "We recognize the importance of transparency and collaboration in the XR development process. Our experienced team is dedicated to sharing our knowledge and expertise with you, offering comprehensive consultation at every stage of your XR project's development journey.",
  },
]
